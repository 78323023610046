import {HttpParameterCodec} from '@angular/common/http';

export class ParamsEncoder implements HttpParameterCodec {
    public decodeKey(key: string): string {
        return decodeURIComponent(key);
    }

    public decodeValue(value: string): string {
        return decodeURIComponent(value);
    }

    public encodeKey(key: string): string {
        return encodeURIComponent(key);
    }

    public encodeValue(value: string): string {
        return encodeURIComponent(value);
    }
}
