import {Action, createSelector, Selector, State, StateContext, Store} from '@ngxs/store';
import {Injectable} from '@angular/core';
import {
    GetNextTicket,
    GetPastTickets,
    GetPrevTicket,
    GetTickets,
    GetValidTickets,
    GetNextTickets,
} from './tickets.actions';
import {ITicket} from 'src/app/interfaces/general/profile-definitions/ticket.interface';
import {ItemPayload} from 'src/app/interfaces/general/responses/item-payload.interface';
import {Navigate} from '@ngxs/router-plugin';
import {firstValueFrom} from 'rxjs';
import {AppInsightsService} from 'src/app/core/app-insights/app-insights.service';
import {errorHandler, httpErrorToString, IErrorHandlerArgs} from 'src/app/shared/helpers/error-handler';
import {TicketsService} from 'src/app/modules/membership/tickets.service';

export interface ITicketsState {
    tickets: ITicket[];
    loading: boolean;
    nextPageToken: string;
    hasValue: boolean;
    scope?: 'All' | 'CurrentAndFuture' | 'Past';
    error: any,
}

@State<ITicketsState>({
    name: 'tickets',
    defaults: {
        tickets: [],
        loading: false,
        nextPageToken: null,
        hasValue: false,
        scope: null,
        error: null,
    },
})
@Injectable()
export class TicketsState {
    private readonly _errorHandlerArgsInit: IErrorHandlerArgs = {
        error: null,
        appInsightsSrv: this.insights,
        scope: 'TicketsState',
    };
    constructor(
        private ticketsService: TicketsService,
        private store: Store,
        private insights: AppInsightsService,
    ) { }

    @Selector()
    public static tickets(state: ITicketsState): ITicket[] {
        return state.tickets;
    }

    @Selector()
    public static loading(state: ITicketsState): boolean {
        return state.loading;
    }

    @Selector()
    public static hasValue(state: ITicketsState): boolean {
        return !!state.tickets?.length;
    }

    public static hasPrevValue(id: string) {
        return createSelector([TicketsState], (state: ITicketsState) => {
            return state.tickets?.findIndex((ticket) => ticket.identifier === id) > 0;
        });
    }

    public static hasNextValue(id: string) {
        return createSelector([TicketsState], (state: ITicketsState) => {
            return (
                state.tickets.findIndex((ticket) => ticket.identifier === id) <
                state.tickets.length - 1
            );
        });
    }

    @Action(GetTickets)
    public async getTickets(ctx: StateContext<ITicketsState>): Promise<any> {
        ctx.patchState({loading: true, error: null});
        try {
            const ticketsState: ItemPayload<ITicket> = await firstValueFrom(
                this.ticketsService.getTickets()
            );
            const filteredTickets = ticketsState.data;
            ctx.patchState({
                tickets: filteredTickets,
                hasValue: !!ticketsState.data.length,
                nextPageToken: ticketsState.nextPageToken,
                loading: false,
            });
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                loading: false,
                error: httpErrorToString(error),
            });
        }

    }

    @Action(GetValidTickets)
    public async getValidTickets(ctx: StateContext<ITicketsState>): Promise<any> {
        ctx.patchState({loading: true, tickets: [], error: null});
        try {
            const ticketsState: ItemPayload<ITicket> = await firstValueFrom(
                this.ticketsService.getValidTickets()
            );
            const filteredTickets = ticketsState.data.filter((ticket) => ticket.additionalType === 'Membership');
            ctx.patchState({
                tickets: filteredTickets,
                hasValue: !!ticketsState.data.length,
                nextPageToken: ticketsState.nextPageToken,
                loading: false,
                scope: 'CurrentAndFuture',
            });
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                loading: false,
                error: httpErrorToString(error),
            });
        }
    }

    @Action(GetPastTickets)
    public async getPastTickets(ctx: StateContext<ITicketsState>): Promise<any> {
        ctx.patchState({loading: true, tickets: [], error: null});
        try {
            const ticketsState: ItemPayload<ITicket> = await firstValueFrom(
                this.ticketsService.getPastTickets()
            );
            const filteredTickets = ticketsState.data.filter((ticket) => ticket.additionalType === 'Membership');
            ctx.patchState({
                tickets: filteredTickets,
                hasValue: !!ticketsState.data.length,
                nextPageToken: ticketsState.nextPageToken,
                loading: false,
                scope: 'Past',
            });
        } catch (error) {
            errorHandler({...this._errorHandlerArgsInit, error});
            ctx.patchState({
                loading: false,
                error: httpErrorToString(error),
            });
        }
    }

    @Action(GetNextTickets)
    public async getNextTickets(ctx: StateContext<ITicketsState>): Promise<any> {
        const tickets = ctx.getState().tickets;
        const nextPageToken = ctx.getState().nextPageToken;
        const scope = ctx.getState().scope ?? 'All';
        if (nextPageToken) {
            ctx.patchState({loading: true, error: null});
            try {
                const ticketsState: ItemPayload<ITicket> = await firstValueFrom(
                    this.ticketsService.getTickets(scope, nextPageToken)
                );
                const filteredTickets = ticketsState.data.filter((ticket) => ticket.additionalType === 'Membership');
                ctx.patchState({
                    tickets: [...tickets, ...filteredTickets],
                    nextPageToken: ticketsState.nextPageToken,
                    loading: false,
                    scope,
                });
            } catch (error) {
                errorHandler({...this._errorHandlerArgsInit, error});
                ctx.patchState({
                    loading: false,
                    error: httpErrorToString(error),
                });
            }
        }
    }

    @Action(GetPrevTicket)
    public async getPrevTicket(
        ctx: StateContext<ITicketsState>,
        {id}: GetPrevTicket
    ): Promise<any> {
        const state = ctx.getState();
        const tickets = state.tickets;
        const index = tickets?.findIndex((order) => order.identifier === id);
        if (index > 0) {
            this.store.dispatch(new Navigate(['/', 'membership', tickets[index - 1].identifier]));
        }
    }

    @Action(GetNextTicket)
    public async getNextTicket(
        ctx: StateContext<ITicketsState>,
        {id}: GetNextTicket
    ): Promise<any> {
        const state = ctx.getState();
        const tickets = state.tickets;
        const index = tickets.findIndex((order) => order.identifier === id);
        if (index === tickets.length - 2) {
            this.store.dispatch(new GetNextTickets());
            this.store.dispatch(new Navigate(['/', 'membership', tickets[index + 1].identifier]));
        }

        if (index < tickets.length - 2) {
            this.store.dispatch(new Navigate(['/', 'membership', tickets[index + 1].identifier]));
        }
    }
}
