export class GetTickets {
    public static readonly type = '[Tickets] Get tickets';
}

export class GetValidTickets {
    public static readonly type = '[Tickets] Get valid tickets';
}

export class GetPastTickets {
    public static readonly type = '[Tickets] Get past tickets';
}

export class GetNextTickets {
    public static readonly type = '[Tickets] Get next tickets';
}

export class GetNextTicket {
    public static readonly type = '[Tickets] Get next ticket';
    constructor(public id: string) {
    }
}

export class GetPrevTicket {
    public static readonly type = '[Tickets] Get prev ticket';
    constructor(public id: string) {
    }
}
