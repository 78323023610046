import {HttpClient, HttpParams} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {map, Observable} from 'rxjs';
import {ConfigurationService} from 'src/app/core/config/configuration.service';
import {ITicket} from 'src/app/interfaces/general/profile-definitions/ticket.interface';
import {IPaymentMethodResponse} from 'src/app/interfaces/general/responses/payment-method-response.interface';
import {PaymentProvider} from 'src/app/interfaces/payment-provider.type';

export interface GetPaymentMethodsRequest {
    paymentProvider: PaymentProvider;
}

@Injectable({
    providedIn: 'root',
})
export class MarketplaceApiService {
    private readonly url = this.cfg.get('marketplaceApi');
    constructor(
        private cfg: ConfigurationService,
        private http: HttpClient,
    ) {}

    public getPaymentMethods(
        param?: Partial<GetPaymentMethodsRequest>,
    ): Observable<IPaymentMethodResponse> {
        const {paymentProvider} = param ?? {};
        let httpParams = new HttpParams();
        if (paymentProvider) {
            httpParams = httpParams.set('paymentProvider', paymentProvider);
        }
        return this.http
            .get<IPaymentMethodResponse>(`${this.url}/paymentmethod`, {params: httpParams})
            .pipe(
                map((response) => {
                    const paymentMethods = response.paymentMethods ?? [];
                    return {...response, paymentMethods};
                }),
            );
    }

    public activateAutomaticRenewal(membershipId: string): Observable<ITicket> {
        return this.handleAutomaticRenewal({membershipId, ticketRenewStatus: 'AutomaticRenew'});
    }

    public deactivateAutomaticRenewal(membershipId: string): Observable<ITicket> {
        return this.handleAutomaticRenewal({membershipId, ticketRenewStatus: null});
    }

    public handleAutomaticRenewal(param: {
        membershipId: string;
        ticketRenewStatus: 'AutomaticRenew' | null;
    }): Observable<ITicket> {
        const {membershipId, ticketRenewStatus} = param;
        return this.http.put<ITicket>(`${this.url}/tickets/${membershipId}`, {ticketRenewStatus});
    }
}
